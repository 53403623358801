import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
//import styles from '../components/base.css'

class notFound extends React.Component{
    render(){
        const siteTitle = ('Kiwok - About')

        return (
            <Layout location={this.props.location} >
            <div className="containerWrapper">
              <div style={{ background: '#fff' }}>
                <Helmet title={siteTitle} />
                
                <div className="wrapperPage">
                    <h2>Error 404 - This page could not be found</h2>
                    <p>Sorry, the page you are looking for does not exist. Please navigate to the home page or use the menu to find the page you are looking for.</p>
                </div>
              </div>
            </div>
          </Layout>
        )
    }
}

export default notFound